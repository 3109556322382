import React from 'react';

import { Link } from 'react-router-dom';

import { Dropdown } from 'appComponents/Dropdown';

import { AppMenu } from './components/AppMenu';
import { Nav } from './components/Nav';
import { Search } from './components/Search';

import { UserMenu } from './components/UserMenu';
import * as S from './styled';
import { useHeaderData } from './useHeaderData';
import plusIcon from '../../assets/icons/plus.svg';

export const AppHeader = () => {
  const {
    currentApp,
    availableApps,
    searchBar,
    callToAction,
    selectedMembership,
    availableMemberships,
    clearSelectedMembership,
    items,
  } = useHeaderData();
  return (
    <S.HeaderWrapper>
      <meta content={currentApp.color} name="theme-color" />

      <AppMenu
        appName={currentApp.name}
        availableApps={availableApps}
        color={currentApp.color}
      />
      <Nav items={items} />

      <S.CtaRow>
        {searchBar && (
          <Search onSearch={searchBar.onSearch} placeholder={searchBar.placeholder} />
        )}
        {callToAction && (
          <Dropdown
            align="right"
            color="secondary"
            toggleComponent={(
              <S.Cta>
                <img alt="" src={plusIcon} />
                {callToAction.title}
              </S.Cta>
            )}
            variant="secondary">
            {callToAction.items?.filter(i => !i.hidden && i.href)
              // @ts-expect-error href is marked as ?string
              .map(item => <Link key={item.href} to={item.href}>{item.title}</Link>)}
          </Dropdown>
        )}
        {selectedMembership && (
          <UserMenu
            availableMemberships={availableMemberships}
            goToAllMemberships={clearSelectedMembership}
            selectedMembership={selectedMembership}
          />
        )}
      </S.CtaRow>
    </S.HeaderWrapper>
  );
};
