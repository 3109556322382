import styled, { css } from 'styled-components';

export const BaseLinkStyles = css`
  min-height: 40px;
  background-color: #1854d9;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 5rem;

  &:hover,
  &:focus {
    background-color: #0f3ea6;
  }

  &:disabled {
    opacity: 0.25;
  }
`;

export const SecondaryLinkStyles = css`
  min-height: 40px;
  background-color: transparent;
  font-size: 0.75rem;
  color: #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  top: -1px;
  border-radius: 4px;
  order: 999;

  &:hover,
  &:focus {
    background-color: #eee;
  }

  &:disabled {
    opacity: 0.25;
  }
`;

export const StyledLinkButton = styled.a<{
  fullWidth?: boolean;
  variant: 'primary' | 'secondary';
}>`
  ${({ variant }) => (variant === 'primary' ? BaseLinkStyles : SecondaryLinkStyles)}

  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
    `};
`;

export const StyledButton = styled.button<{
  fullWidth?: boolean;
  variant: 'primary' | 'secondary';
  color: 'primary' | 'danger';
}>`
  ${({ variant }) => (variant === 'primary' ? BaseLinkStyles : SecondaryLinkStyles)}
  border: 0;
  cursor: pointer;
  gap: 8px;

  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
    `};

  ${({ color }) => color === 'danger'
    && css`
      color: ${({ theme }) => theme.palette.red[400]};
    `};

  svg * {
    stroke: currentColor;
  }
`;
