import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MAX_WIDTH } from 'config/style';

export const StyledWrapper = styled.div`
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
`;

export const StyledContainer = styled.div`
  max-width: ${MAX_WIDTH};
  width: 100%;
  margin: 0 auto;
`;

export const StyledTitle = styled.h1`
  font-size: 1rem;
  font-weight: 500;
  margin: 0;

  span {
    color: ${({ theme }) => theme.palette.clay[500]};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 8px;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 0;
`;

export const UpdateAndNotificationSection = styled.div`
  background-color: #faf9f9;
  padding: 32px 0;
  margin-top: auto;
  flex: 1;
  display: flex;

  ${({ expanded, empty }) => (expanded || empty) && css`

    > div > div > h1 + div  {
      max-height: calc(100vh - 650px);
    }
  `}

  ${StyledContainer} {
    display: flex;
    gap: 120px;

    > div {
      flex: 1.3;

      &:first-child {
        flex-grow: 2;
      }
    }
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const UpdateRow = styled(Link)`
  padding: 1rem 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background-color: ${({ $alert }) => ($alert ? '#FFCE01' : '#fff')};
  min-height: 140px;
  font-size: 12px;

  /* &:hover {
    box-shadow: ${({ theme }) => theme.elevation.light};
  } */

  .date {
    color: ${({ theme }) => theme.palette.clay[500]};
    font-size: 10px;
  }
  .name {
    color: ${({ theme }) => theme.palette.clay[800]};
    font-size: 12px;
    font-weight: 500;
  }
  .description {
    color: ${({ theme }) => theme.palette.clay[800]};
    font-size: 12px;
  }

  /* img {
    display: ${({ $alert }) => ($alert ? 'block' : 'none')};
  } */
`;

export const ItemsContainer = styled.div`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 545px);
  gap: ${({ size = 'sm' }) => (size === 'sm' ? '8px' : '16px')};
  overflow: auto;
  position: relative;
`;

export const EmptyListMessage = styled.div`
  color: #aaaaaa;
  display: block;
  margin: auto 0;
  font-size: 14px;
`;

export const ItemsHeader = styled.div`
  font-weight: normal;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 30px;
  padding: 0 18px 12px 18px;
  font-size: 0.625rem;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #FAFAF8;

  >div:first-child {
    position: relative;
    left: -18px;
  }
`;

export const ItemsSecondaryContainer = styled(Link)`
  font-weight: normal;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 30px;
  padding: 18px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 0.625rem;
  align-items: center;

  &:last-of-type {
    margin-bottom: 12px;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    font-size: 0.75rem;
  }

  .sm {
    color: ${({ theme }) => theme.palette.clay[500]};
    font-weight: 400;
    margin-top: 4px;
    font-size: 0.625rem;
  }

  .date {
    color: #E3A100;
    display: flex;
    align-items: center;
    gap: 4px;

    &.expired {
      color: #A50808;

      svg * {
        stroke: #A50808;
      }
    }
  }
`;

export const AnnouncementsRowSecondary = styled(Link)`
  padding: 18px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-width: 400px;

  .title {
    font-size: 0.75rem;
  }

  .date {
    color: ${({ theme }) => theme.palette.clay[500]};
    font-weight: 400;
    font-size: 0.625rem;
  }
`;

export const AnnouncementsRow = styled(Link)`
  padding-right: 1rem;

  .title {
    font-size: 0.75rem;
  }

  .date {
    color: ${({ theme }) => theme.palette.clay[500]};
    font-weight: 400;
    margin-top: 4px;
    font-size: 0.625rem;
  }
`;

export const StyledTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const NotificationsContainer = styled.div`
  border-bottom: 1px solid #ebebeb;
`;

export const FooterContainer = styled.div`
  margin-bottom: 5rem;
  border-top: 1px solid #e4e4e4;
  padding-top: 1.625rem;
`;

export const NoDataWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;

  span {
    font-size: 12px;
    color: #aaaaaa;
  }
`;

export const NoDataWrapperSecondary = styled.div`
  padding: 1.25rem 0.5rem 0.75rem;
  min-height: 80%;

  span {
    font-size: 14px;
    color: #aaaaaa;
  }
  gap: 50px;
  display: flex;
  flex-direction: column;

  svg {
    display: block;
    margin: auto;
  }
`;

export const LinksRow = styled.div`
  display: flex;
  gap: 16px;
`;
