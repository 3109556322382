/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

import * as types from 'state/auth/auth.types';

import { store } from '../state/index';
/* import { isUsingProxy } from './proxy'; */

const cacheAdapter = throttleAdapterEnhancer(
  cacheAdapterEnhancer(axios.defaults.adapter), // defaults: { maxAge: FIVE_MINUTES, max: 100 }
  { threshold: 4000 }, // 4s in milliseconds
);

const baseConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  crossdomain: true,
  headers: {
    Accept: 'application/ld+json, application/json',
    'Content-Type': 'application/json',
  },
};

export const versionedConfig = {
  ...baseConfig,
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
};

export const versionedApi = axios.create(versionedConfig);
export const api = axios.create(baseConfig);
export const epicApi = axios.create({
  ...baseConfig,
  baseURL: process.env.REACT_APP_SHARE_DOMAIN,
});

const requestInterceptor = (req) => {
  const {
    auth,
    user,
    company,
  } = store.getState();
  const { userToken } = auth;
  const selectedMembership = req.overrideMembership || user?.selectedMembership;
  const { id: companyId } = company || {};

  if (selectedMembership && !req?.removeNibeHeaders) {
    req.headers = {
      ...req.headers,
      'Nibe-CompanyId': selectedMembership?.company?.id,
      'Nibe-View': selectedMembership?.view,
      'Nibe-UserId': selectedMembership?.user?.id,
      'Nibe-MembershipId': selectedMembership?.id,
    };
  }

  /* if (isUsingProxy(req?.url)) {
    req.baseURL = process.env.REACT_APP_MOCK_API_URL;
    req.url = req.url?.replace(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
      process.env.REACT_APP_MOCK_API_URL
    );
  } */

  if (req?.fileHeader) {
    req.headers = { ...req.headers, ...req.fileHeader };
  }

  if (userToken) {
    req.headers.Authorization = `Bearer ${userToken}`;
  }

  if (companyId && !req.params?.owner) {
    req.params = {
      ...req.params,
      owner: companyId,
    };
  }

  if ((req.params?.owner === 'none')) {
    delete req.params.owner;
  }

  if (req.cache) {
    req.adapter = cacheAdapter;
  }

  return req;
};

const responseInterceptor = (response) => {
  if (response.config.dontParse) return response;
  return response.data;
};
// eslint-disable-next-line consistent-return
const responseErrorInterceptor = (extractData) => async (error) => {
  const originalRequest = error?.config;
  if (error?.response?.status === 401 && !originalRequest._retry
    && !originalRequest?.url?.includes('login_check')) {
    originalRequest._retry = true;
    store.dispatch({ type: types.REFRESH_REQUESTED });

    const isTokenRefreshed = await new Promise((resolve) => {
      const subscription = store.subscribe(() => {
        const { tokenRefreshState } = store.getState().auth;

        if (tokenRefreshState === 'done') {
          subscription();
          resolve(true);
        } else if (tokenRefreshState === 'failed') {
          subscription();
          resolve(false);
        }
      });
    });

    if (!isTokenRefreshed) {
      throw error;
    }

    originalRequest.headers.Authorization = `Bearer ${store.getState().auth?.userToken}`;
    return axios(originalRequest)
      .then(res => (extractData ? res.data : res));
  }
  throw error;
};

versionedApi.interceptors.request.use(requestInterceptor);
api.interceptors.request.use(requestInterceptor);
epicApi.interceptors.request.use(requestInterceptor);

versionedApi.interceptors.response.use(responseInterceptor, responseErrorInterceptor(true));
api.interceptors.response.use(r => r, responseErrorInterceptor());
epicApi.interceptors.response.use(r => r, responseErrorInterceptor());

export default versionedApi;
