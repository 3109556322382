import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Arrow = styled.div<{ color: 'primary' | 'secondary' }>`
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.palette.clay[800]};
  position: absolute;
  top: -4px;
  right: 16px;
  transform: rotate(45deg);
`;

export const DropDown = styled.div<{
  align: 'left' | 'right';
  offset?: 'small' | 'large';
  variant: 'primary' | 'secondary';
  color: 'primary' | 'secondary';
}>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 240px;
  padding: ${({ variant }) => (variant === 'primary' ? '0' : '8px 0')};
  box-shadow: ${({ theme }) => theme.elevation.light};
  background-color: ${({ theme }) => theme.palette.clay[800]};
  border-radius: ${({ variant }) => (variant === 'secondary' ? '4px' : '0')};
  position: absolute;
  top: ${({ variant }) => (variant === 'primary' ? '100%' : 'calc(100% + 7px)')};
  left: ${({ align }) => (align === 'left' ? '0' : 'auto')};
  right: ${({ align }) => (align === 'right' ? '-6px' : 'auto')};
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 250ms;

  > a {
    color: #fff;
    padding: 11px 16px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 18px;
    transition: 100ms;

    &:hover {
      font-weight: 500;
    }
  }
`;

export const Toggle = styled.button<{
  offset?: 'small' | 'large';
  withTitle?: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  min-width: ${({ offset }) => (offset === 'large' ? '34px' : '24px')};
  min-height: ${({ offset }) => (offset === 'large' ? '34px' : '24px')};

  ${({ withTitle }) => withTitle
    && css`
      min-height: auto;
      min-height: auto;
    `}

  img {
    width: 12px;
  }

  &:after {
    content: "";
    transition: 250ms;
  }
`;

export const Wrapper = styled.div`
  display: inline-flex;
  position: relative;

  &:hover {
    ${DropDown} {
      opacity: 1;
      visibility: visible;
    }
  }

  /* &:hover {
   ${Toggle} {
    &:after {
    position: absolute;
    left: -6px;
    right: -6px;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.05);
   }
   }
  } */
`;

export const OptionButton = styled(Link)`
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 400;
  width: 100%;

  &:hover {
    background-color: #f4f8fa;
  }
`;

export const OptionLink = styled.button`
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 10px 16px;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  width: 100%;

  color: #1854d9;

  &:hover {
    background-color: #f4f8fa;
  }
`;

export const OptionsHeading = styled.div`
  padding: 12px 16px 10px 16px;
  line-height: 18px;
  border-bottom: 1px solid #efeeec;
  font-size: 11px;
  color: #aaaaaa;
  font-weight: 400;
`;

export const OptionFooter = styled.div`
  font-size: 12px;
  line-height: 18px;
  border-top: 1px solid #efeeec;
  font-weight: 400;
`;
export const CollectionsToggle = styled.div`
  border-radius: 3px;
  min-width: 31px;
  height: 22px;
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: center;
  background-color: transparent;
  color: #aaaaaa;

  svg {
    opacity: 0;
  }

  svg * {
    stroke: #aaaaaa;
  }

  &:hover {
    color: #1854d9;
    background: #f3f6fd;

    svg {
      opacity: 1;
    }

    svg * {
      stroke: #1854d9;
    }
  }
`;
