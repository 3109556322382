import * as types from './user.types';

const initialState = {
  email: null,
  locale: process.env.REACT_APP_DEFAULT_LANG,
  membershipSize: 0,
  recentMembershipIds: [],
};

const user = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_USER:
  case types.GET_USER: {
    return { ...state, ...action.payload };
  }
  case types.SET_USER_MEMBERSHIP: {
    return {
      ...state,
      ...action.payload,
      recentMembershipIds: action.payload.selectedMembership?.id ? [
        action.payload.selectedMembership.id,
        ...(state.recentMembershipIds
          ?.filter(id => id !== action.payload.selectedMembership.id) || []),
      ].slice(0, 5) : state.recentMembershipIds,
    };
  }

  default:
    return state;
  }
};

export default user;
