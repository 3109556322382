import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { users as usersService } from 'services';

import { getPermissions } from 'services/permissions';
import { setMembership } from 'state/user/user.actions';
import { AccessControlListKey } from 'types/AccessControlLists';
import { Membership, MembershipRole } from 'types/Membership';

const userMembershipsFetcher = (params: unknown[]) => usersService
  .getUserMemberships(params[1], {
    params: JSON.parse(params[2] as string),
  }, params[3]).then(r => r.users as (Membership)[]);

export const useUserMemberships = (
  id: string | number,
  params: {'order[company.name]'?: 'asc' | 'desc'} = { 'order[company.name]': 'asc' },
  removeNibeHeaders: boolean | undefined = undefined,
) => useSWR(!id ? null : ['user memberships', String(id), JSON.stringify({
  ...params,
  user: id,
  // Api already returns data in company (with roles) format. old impl parses it into the other structure

}), removeNibeHeaders], userMembershipsFetcher, {
  revalidateOnFocus: false,
  isPaused: () => !id,
});
export const useMemberships = () => {
  const {
    id,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useSelector(({ user }) => user);

  return useUserMemberships(String(id), undefined, true);
};

export const useCurrentMembership = () => {
  const {
    selectedMembership,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useSelector(({ user }) => user);
  const dispatch = useDispatch();

  const { mutate } = useSWRConfig();
  const changeMembership = useCallback((
    membership: Membership,
    role:
    MembershipRole = membership.roles[0],
  ) => {
    // UNTESTED, check nav.jsx for legacy impl.
    dispatch(setMembership({
      ...membership,
      role,
      roleName: role.name,
      view: role.view,
    }));
    return mutate(
      (key) => (key && typeof key === 'object' && 'keepOnMembershipSwitch' in key ? !key.keepOnMembershipSwitch : true),
      undefined,
      { revalidate: true, populateCache: true },
    );
  }, [dispatch, mutate]);

  const nav = useNavigate();

  const clearSelectedMembership = useCallback(() => {
    nav('/');
    dispatch(setMembership(null));
    mutate(
      (key: unknown) => (key && typeof key === 'object' && 'keepOnMembershipSwitch' in key && !key.keepOnMembershipSwitch),
      undefined,
      { revalidate: true, populateCache: true },
    );
  }, [dispatch, mutate, nav]);

  return {
    changeMembership,
    clearSelectedMembership,
    selectedMembership: selectedMembership as null | Membership & {
      role: MembershipRole, view: string, value: string, label: unknown},
  };
};

const usePermissionsFetcher = () => getPermissions();
export const usePermissions = () => {
  const { selectedMembership } = useCurrentMembership();
  const permissions = useSWR(!selectedMembership ? null : ['permissions', selectedMembership.id], usePermissionsFetcher, {
    revalidateOnFocus: false,
    dedupingInterval: /* 10 min */ 1000 * 60 * 10,
  });

  return {
    selectedMembership,
    permissions: permissions.data,
  };
};

export const useHasPermission = (permission: AccessControlListKey) => {
  const { permissions } = usePermissions();

  return permissions?.[permission] || false;
};

export const useHasAtLeastOnePermission = (permissions: AccessControlListKey[]) => {
  const { permissions: userPermissions } = usePermissions();

  return permissions.some(p => userPermissions?.[p]);
};

const allRolesAndPermissionsFetcher = async (
  { memberships }: {key: string, memberships: Membership[]},
) => Promise.all(
  memberships.map(async (m) => ({
    ...m,
    roles: await Promise.all(m.roles.map(async role => ({
      ...role,
      permissions: await getPermissions({ ...m, view: role.view }),
    }))),
  })),
);

export const useAllMembershipsWithPermissions = () => {
  const { data: memberships } = useMemberships();

  const { data } = useSWR(!memberships ? null : { key: 'all roles permissions', memberships, keepOnMembershipSwitch: true }, allRolesAndPermissionsFetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return data;
};
