import mime from 'mime';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { useTranslation } from 'react-i18next';

import viewIcon from 'assets/calculationsIcons/review.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/input-clear.svg';
import { withLabels } from 'components/Field';

import * as S from './Input.styled';

const inputTypes = [
  'color',
  'date',
  'datetime-local',
  'email',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'time',
  'url',
  'week',
  'file',
];

const Input = ({
  id,
  name,
  ref,
  innerRef = ref,
  type,
  translation,
  input,
  hideShowIcon,
  containerStyle,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputName = name || id;

  const viewPassword = () => setIsVisible(!isVisible);

  const fileInputValue = (type === 'file') ? { value: undefined } : null;

  const { i18n, t } = useTranslation(undefined, { keyPrefix: 'fileUpload' });

  // Can't use useSelector here otherwise this crashes if an input is used in a modal. See EPD-2016
  const locale = i18n.language;

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    input.onChange(acceptedFiles);
  }, [input]);
  const {
    getRootProps, getInputProps, isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: props.accept?.split(',').reduce((a, c) => ({ ...a, [c]: [] }), {}),
  });

  if (type === 'file') {
    if (input.value?.[0]) {
      return (
        <S.FileStrip>
          {input.value[0].name}
          <button
            onClick={e => {
              e.preventDefault();
              input.onChange(undefined);
            }}
            type="button">
            <ClearIcon />

          </button>
        </S.FileStrip>
      );
    }

    let acceptedExtensions = props.accept?.split(',').map(m => mime.getExtension(m)).filter(e => !!e) || [];
    if (acceptedExtensions.includes('jpeg')) acceptedExtensions.push('jpg');

    acceptedExtensions = acceptedExtensions.sort();

    return (
      (
        <S.InputContainer style={containerStyle}>
          <S.FileInputWrapper {...getRootProps()}>
            <S.Input
              id={inputName}
              name={inputName}
              type={isVisible ? 'text' : type}
              {...props}
              {...input}
              {...fileInputValue}
              ref={innerRef || ref}
              autoComplete="new-password"
              {...(type === 'file' ? getInputProps() : {})}
            />
            {/* eslint-disable-next-line no-nested-ternary */}
            {isDragActive ? (
              <>
                {isDragAccept && (t('dragAccepted') || 'Drop the file here')}
                {isDragReject && (t('dragRejected') || ('File type not accepted, sorry!'))}
              </>
            ) : (
              input.value?.[0] ? (
                <>
                  {input.value[0].name}
                  <br />
                  <small>Click to replace, or drag another file</small>
                  <button onClick={() => input.onChange(undefined)} type="button">X</button>
                </>
              ) : t('hint') || 'Drag and drop files here or upload'
            )}
          </S.FileInputWrapper>
          <S.FileInputRequirementsLine>
            {t('maxFileSizeHint', { maxSizeInMb: props.maxSizeInMb }) || `Max file size is ${props.maxSizeInMb}MB.`}
            {' '}
            {!props.hideExtensions && acceptedExtensions.length > 0 && (
              <>
                {t('supportedFileTypesInt', {
                  acceptedExtensions: new Intl.ListFormat(locale)
                    .format(acceptedExtensions),
                })}
                {acceptedExtensions.map(ext => (
                  <span key={ext}>
                    {t(`extensionHint.${ext}`, { defaultValue: '' })}
                  </span>
                ))}
              </>
            )}

            {t('test', { defaultValue: '' })}

          </S.FileInputRequirementsLine>
        </S.InputContainer>
      )
    );
  }

  return (
    <S.InputContainer style={containerStyle}>
      <S.Input
        id={inputName}
        name={inputName}
        type={isVisible ? 'text' : type}
        {...props}
        {...input}
        {...fileInputValue}
        ref={innerRef || ref}
        autoComplete="new-password"
        {...(input?.value && { value: type === 'date' ? moment(input.value).format('YYYY-MM-DD') : input.value })}
      />

      {type === 'password' && !hideShowIcon && (
        <button onClick={viewPassword} type="button">
          <img alt="view password" src={viewIcon} />
          {isVisible && <span />}
        </button>
      )}
    </S.InputContainer>
  );
};

Input.defaultProps = {
  type: 'text',
  name: undefined,
  required: false,
  disabled: false,
  readOnly: false,
  placeholder: undefined,
  value: undefined,
  autoComplete: 'on',
};

Input.propTypes = {
  type: PropTypes.oneOf(inputTypes),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const FormInput = withLabels(Input);

export default memo(Input);
